@import '../../style/settings';

.social-network {
  
  position: absolute;
  right: 1rem;
  top: 0;
  width: 20%;
  font-size: 1.2rem;
  z-index: +12;

  @media screen and (max-width: 650px){
    width: 30%;
  }


  ul {
    display: flex;
    width: 100%;
    min-width: 100px;
    justify-content: space-around;

    @media screen and (max-width: 450px){
      min-width: 100%;
    }

    a {
      padding: 1rem;
      transition: 1.1s ease-out;
    }
  }
}