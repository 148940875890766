@import '../../style/settings';

.notFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height:100vh;
    align-items: center;
    text-align: center;
  
    h2 {
      font-size: 4rem;
      padding: 2rem;
  
      &:after {
        content: '';
        height: 500px;
        width: 500px;
        background: $color-3;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }
    h3 {
      font-size: 2rem;
      transition: .4s;
      padding: 10px;
  
      &:hover {
        transform: scale(1.3);
      }
    }
  }