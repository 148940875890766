.partage{

background-image: linear-gradient(to top, #9be15d 90%, #00e3ae 100%);
color: rgba(29, 24, 24, 0.623);
padding: 8rem;
max-width: 100%;
font-size: small;

.react{

    width:3rem;
    
}

img{
    width: 20%;
    
    }

}



    
