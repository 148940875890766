.tips{

color:black;
background-image: linear-gradient(to top, #9be15d 90%, #00e3ae 100%);
height: 100vh;
color: rgba(29, 24, 24, 0.623);
padding: 8rem;

.react{

    width:3rem;
    
}

img{
    width: 20%;
    
    }
}