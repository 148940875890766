@font-face {
    font-family: "font-1";
    src: url(../assets/fonts/Questrial-Regular.ttf);
  }
  @font-face {
    font-family: "font-2";
    src: url(../assets/fonts/KronaOne-Regular.ttf);
  }
  
  $font-1: "font-1", sans-serif;
  $font-2: "font-2", serif;
  
  $color-1: linear-gradient(120deg, #182b30 50%, #0bbae6 100%); //baground
  $color-2:  rgb(11, 187, 241);  //titre projet //
  $color-3:  linear-gradient(130deg, #1c71a1 5%, #fdab85 100%); //animation boule
  $color-4: rgb(245, 243, 243); //couleur menu, social icon h2
  $black: rgb(110, 100, 100); // h1 h3
  $red: rgba(255, 0, 0, 0.938);
  
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    outline: none;
    border: none;
    text-decoration: none;
   
  
    &::selection {
      
      background: $color-2;
    }
  }
  html { 
    scroll-behavior: unset;
  }
  a,
  a:visited {
    color: $color-4;
  }
  body {
    font-family: $font-1;
    height: 100vh;
    background-color: $color-1;
    color: $black;
    overflow: hidden;
  
    @media screen and (max-width: 430px){
      position: relative;
      max-width: 100vw;
      overflow-y: initial;
    }
  }
  
  
  main {
    position: relative;
    height: 100%;
    
    
  }