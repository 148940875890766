button {
    box-shadow: 5px 3px 2px 1px rgba(5, 5, 5, 0.2);
    
    border-radius: 5px;
    z-index: 1;
    position: relative;
    font-size: 1rem;
    font-family: inherit;
    color: white;
    padding: 0.50em 0.8em;
    border: none;
    background-image: linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%);
    overflow: hidden;
    transition: color 0.7s ease-in-out;
  }
  
  button::before {
    content: '';
    z-index: -1;
    position: absolute;
    bottom: 100%;
    right: 100%;
    width: 1.5em;
    height: 1em;
    border-radius: 50%;
    background-image: linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 67%, #FFF800 100%);
    transition: transform 0.5s ease-in-out;
  }
  
  button:hover::before {
    transform: translate3d(50%, 50%, 0) scale3d(15, 15, 15);
  }
  