@import "../../style/settings";

.project {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: black;


  .navigation {
    position: absolute;
    right: 20%;
    top: 0;
  }

  &:after {
    content: "";
    height: 700px;
    width: 700px;
    border: 1px solid $color-2;
    border-radius: 50%;
    position: absolute;
    bottom: -200px;
    left: -80px;
    z-index: -12;

    @media screen and (max-width: 810px) {
      height: 200px;
      width: 200px;
      bottom: -110px;
    }
    @media screen and (max-width: 430px) {
      // display: none;
      left: -500px;
    }
  }
  @media screen and (max-width: 560px) {
    margin-right: 1rem;
  }
  @media screen and (max-width: 560px) {
    margin-right: 1rem;
  }

  @media screen and (max-width: 711px) {
    .languages {
      display: none;
    }

    .date {
      display: none;
    }

    &:after {
      content: "";
      height: 50px;
      width: 200px;
      border: 1px solid $color-2;
      border-radius: 50%;
      position: absolute;
      bottom: -200px;
      left: -80px;
      z-index: -10;

      @media screen and (max-width: 810px) {
        height: 20px;
        width: 20px;
        bottom: -110px;
      }

      @media screen and (max-width: 650px) {
        left: 70%;
      }

      @keyframes navProject {
        to {
          transform: translateY(0);
        }
      }

      &:before {
        left: -120px;
        top: -250px;
      }

      .nav-projects {
        transform: translateX(67px);
        padding: 1em 2rem;
      }
    }
  }

  .logo {
    top: 0;
    padding: 0.5rem;
    margin: 0.5rem;
    transform: translateX(-50%);
    transform: scale(0);
    animation: logoProject 2s ease forwards;

    @keyframes logoProject {
      to {
        transform: scale(1);
      }
    }
  }
  .project-main {
    margin: 8rem 1rem 0 5rem;
    display: flex;
    flex-wrap: wrap;

    .project-content {
      margin-top: 1rem;
      margin-left: 3rem;

      h1 {
        font-size: 3rem;
        margin-bottom: 12px;
        font-family: $font-2;
        color: $color-2;
        transform: scaleX(0);
        animation: transformTitle 1.5s ease forwards;
        transform-origin: 150%;

        @media screen and (max-width: 810px) {
          font-size: 2rem;
        }
        @media screen and (max-width: 450px) {
          font-size: 1.2rem;
        }

        @keyframes transformTitle {
          to {
            transform: scaleX(1);
          }
        }
      }
      p {
        margin-bottom: 30px;
        color: $color-4;
        font-size: 1.2rem;

        @media screen and (max-width: 700px) {
          margin: 0;
        }
        @media screen and (max-width: 450px) {
          font-size: 0.9rem;
        }

        &:after {
          content: "";
          height: 400px;
          width: 400px;
          background: $color-3;
          border-radius: 50%;
          position: absolute;
          top: 180px;
          left: 70px;
          z-index: -2;

          @media screen and (max-width: 810px) {
            height: 220px;
            width: 220px;
            left: -30px;
          }
        }
      }
      li {
        font-size: 1.5rem;

        @media screen and (max-width: 700px) {
          font-size: 1.1rem;
        }
        @media screen and (max-width: 400px) {
          font-size: 0.8rem;
        }
      }
    }
    .img-content {
      position: relative;
      text-align: left;
      width: 100%;
      background-image: cover;

      @media screen and (max-width: 700px) {
        text-align: center;
        margin: 0 auto;
      }

      .img-container {
        border-radius: 5px;
        box-shadow: 0px 2px 4px rgba(255, 254, 254, 0.26);
        width: 50%;
        height: 45vh;
        overflow: hidden;
        position: relative;
        margin-left: 15%;

        @media screen and (max-width: 940px) {
          height: 300px;
          width: 500px;
        }
        @media screen and (max-width: 540px) {
          height: 200px;
          width: 370px;
        }
        @media screen and (max-width: 400px) {
          height: 150px;
          width: 280px;
        }

        &:hover span,
        span:hover {
          visibility: visible;
          transform: translateY(0);
        }

        img {
          overflow: hidden;
          object-fit: cover;
          object-position: 0%;
          width: 100%;
          height: 100%;
          position: absolute;
          transition: 0.4s ease-out;
          position: relative;

          &:hover,
          span:hover img {
            transform: scale(1.2);
            filter: opacity(15%) contrast(150%);
          }
        }
        span {
          position: absolute;
          bottom: 20px;
          left: 0;
          width: 100%;
          padding: 1rem;
          margin: 0 auto;
          line-height: 34px;
          font-size: 1.5rem;
          visibility: hidden;
          transition: 0.5s ease;
          transform: translateY(200px);

          h3 {
            padding-bottom: 10px;
            color: rgb(1, 110, 134);
          }
        }
      }
      .buttonBottom {
        right: 0;
      }
    }
  }

  @media screen and (max-width: 630px) {
    display: none;
  }
}
