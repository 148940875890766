@import "../../style/settings";


.dynamic-text {
  position: relative;
  display: flex;
  z-index: +10;

  .simply {
    color: white;
  }

  #text-target {
    display: flex;
    width: 300px;
    text-align: left;
    animation: color 30s alternate infinite;
    perspective: 200px;
    transform: translateX(26px);
  }
  .word {
    position: absolute;
  }
  @keyframes color {
    0% {
      color: rgb(14, 214, 214);
    }
    50% {
      color: rgb(0, 159, 173);
    }
    100% {
      color: rgb(2, 107, 126);
    }
  }
  .letter {
    transform: translateZ(200px);
  }
  @keyframes anim {
    25% {
      transform: translateZ(0px);
      opacity: 1;
    }
    32% {
      opacity: 0;
      transform: translateZ(-280px) translateX(200px);
    }
  }
}
