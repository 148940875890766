@import "../../style/settings.scss";


.contact {
  
  height: 100vh;
  display: flex;
  flex-direction: row;
  width: 100vw;
  background-color: rgb(10, 15, 31);
  z-index: -2;
  
  p {
    font-size: 1rem;


    @media screen and (min-width: 1200px){
      font-size: 1.5rem;
    }
  }
  @media screen and (max-width:580px){

    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    

  }

  .social{

@media screen and (max-width:669px){

right: 3rem;
margin: 1rem 1rem 1rem 1rem;

}




  }

  &:after {
    content: "";
    height: 700px;
    width: 700px;
    background: $color-3;
    border-radius: 50%;
    position: absolute;
    top: 150px;
    right: -400px;
    z-index: +1;
    animation: contactAnim 25s alternate infinite;

    @media screen and (max-width: 840px){
      height: 500px;
      width: 500px;
      top: 155px;
      right: -440px;
    }
    @media screen and (max-width: 661px){
      display: none;

    }

    @keyframes contactAnim {
      50% {
        transform: scale(1.4);
      }
      100% {
        transform: scale(1);
      }
    }
  }



  .form{

    min-width: 50%;
    margin-left: 10rem;
    margin-top: 9rem;


@media screen and (max-width: 661px){
  text-align: center;
  width: 100%;
  margin: 7rem 2rem 1rem 1rem;
  font-size: 0.8rem;
  
}
  }

  .contact-infos {
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    width: 50%;
    margin: 10%;
    font-size: 1.5rem;
    line-height: 2rem;
    z-index: +2;
    color: aliceblue;
  

.address{

  align-items:flex-start;
  margin: 2rem;
  text-align: end;
  @media screen and (max-width: 661px){
    display: none;
   }
}


.email{

  text-align: center;
  margin: 2rem;
  @media screen and (max-width: 661px){
    display: none;
   }
}
.email:hover{
  color: rgb(3, 117, 173);
}




.phone {

  align-items: flex-start;
  margin: 2rem;
  @media screen and (max-width: 567px){
    display: none;
    justify-content: start;
    align-items: flex-end;
  }

}
.phone:hover{
  color: rgb(3, 117, 173);
}


.pdf{

  @media screen and (max-width:582px) {

    margin-left: -5rem;
    margin-top: -4rem;
   
    
  }

}


}}

