@import "../../style/settings.scss";

.contact-form {
  margin: 100px 5px 10px 100px;
  width: 50%;
  position: relative;
  padding-bottom: 1rem;

  h2 {
    margin-bottom: 20px;

    &:after {
      content: "";
      height: 70px;
      width: 70px;
      border: 1px $color-2 solid;
      border-radius: 50%;
      position: absolute;
      top: -22px;
      left: 133px;
      z-index: -1;
    }
  }

  .send {
    color: rgb(233, 237, 240);
    @media screen and (max-width: 582px) {
      margin-left: -11rem;
    }
  }

  .send:hover {
    color: rgb(4, 152, 172);
  }

  .form-content {
    display: grid;
    grid-template-rows: repeat(4, 1fr) 100px;

    input,
    textarea {
      color: white;
      background: none;
      font-family: $font-1;
      font-size: 1.2rem;
      border-bottom: 1px solid $color-2;

      @media screen and (min-width: 1200px) {
        font-size: 1.5rem;
      }
    }
    input {
      padding: 24px 0 12px;
      width: 100%;
    }
    .email-content {
      position: relative;
      width: 100%;
      border-bottom: 1px solid $color-2;

      @media screen and (max-width: 540px) {
        width: 75%;
      }

      #not-mail {
        display: none;
        position: absolute;
        top: 8px;
        color: $red;
        right: 0;
        transform-origin: 50% 50%;
      }
      @keyframes dongle {
        0% {
          transform: translate(0px, 0px);
        }
        10% {
          transform: translate(-10px, 0px);
        }
        20% {
          transform: translate(10px, 0px);
        }
        30% {
          transform: translate(-10px, 0px);
        }
        40% {
          transform: translate(10px, 0px);
        }
        50% {
          transform: translate(-10px, 0px);
        }
        60% {
          transform: translate(10px, 0px);
        }
        70% {
          transform: translate(-10px, 0px);
        }
        80% {
          transform: translate(10px, 0px);
        }
        90% {
          transform: translate(-10px, 0px);
        }
        100% {
          transform: translate(0px, 0px);
        }
      }
      input {
        border: none;
      }
    }
    textarea {
      resize: none;
      padding: 15px 0;

      @media screen and (max-width: 540px) {
        width: 75%;
        padding: 20px 0;
      }
    }
  }
  .error::placeholder {
    color: $red;
  }
  .form-message {
    margin-top: 10px;
    padding: 12px;
    opacity: 0;
    background: $color-2;
    transition: 0.3s ease;
    color: $color-1;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
  }
}
